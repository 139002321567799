<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader header-text="All Reports">
      <template #subheader>
        List of all Available Reports
      </template>
    </PageHeader>

    <v-container
      v-if="mostPopularReports.length > 0"
      class="pa-0 d-flex flex-column align-center d-sm-block"
      :class="{ 'mobile-view': $vuetify.breakpoint.xsOnly }"
      fluid
    >
      <div class="accounts-box-head mt-10 mb-3">
        Most Popular Reports
      </div>
      <div class="d-flex flex-wrap justify-start">
        <report-card
          v-for="report in mostPopularReports"
          :key="report.id"
          :report="report"
          @click="select_report(report)"
        />
      </div>
    </v-container>

    <div class="accounts-box-head mt-10 mb-3">
      All Reports
    </div>

    <v-container
      class="pa-0 d-flex flex-column align-center d-sm-block"
      :class="{ 'mobile-view': $vuetify.breakpoint.xsOnly }"
      fluid
    >
      <div class="d-flex flex-wrap justify-start">
        <report-card
          v-for="report in allReports"
          :key="report.id"
          :report="report"
          @click="select_report(report)"
        />
      </div>
    </v-container>

    <v-dialog v-model="dialog" scrollable max-width="520px" max-height="90px">
      <ConfigureReportDialog
        v-if="dialog"
        :report="selected_report"
        @click="values => generateReport(selected_report, values)"
        @dismiss="dialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import ReportCard from "@/views/Reports/components/ReportCard";
import ConfigureReportDialog from "@/views/Reports/components/ConfigureReportDialog";
import PageHeader from "@/sharedComponents/PageHeader";
import { range } from 'lodash'

export default {
  name: "ReportsView",
  metaInfo: {
    title: 'All Reports'
  },
  components: {
    ReportCard,
    PageHeader,
    ConfigureReportDialog,
  },
  data() {
    return {
      only_inactive_plugins: false,
      dialog: false,
      selected_report: null,
      segmentSelection: null,
    };
  },
  computed: {
    mostPopularReports() {
      const commonReports = [
        {
          "name": "Email Report",
          "logo": "mdi-email",
          "isActive": true,
          "link": "/reports/email"
        },
        {
          "name": "Banner Report",
          "logo": "mdi-eye",
          "isActive": true,
          "link": "/reports/banner"
        },
      ];

      if (this.$store.getters['user/isReporting']) {
        commonReports.push({
          "name": "Engagement Report",
          "logo": "mdi-account-multiple",
          "isActive": true,
          "link": "/reports/engagement"
        });
      }

      return commonReports;
    },
    allReports() {
      const commonReports = [
        {
          "name": "Email Report",
          "logo": "mdi-email",
          "isActive": true,
          "link": "/reports/email"
        },
        {
          "name": "Banner Report",
          "logo": "mdi-eye",
          "isActive": true,
          "link": "/reports/banner"
        },
        {
          "name": "Contact Device Report",
          "logo": "mdi-laptop",
          "isActive": true,
          "description": "Generate a CSV report that will display detected devices that a contact has used.",
          "parameters": [],
          submit: async () => {
            await this.$rest.exports.post_resource({
              "exportType": "contact_device",
              "exportData": {},
            });

            await this.$router.push({name: "ExportsView"});
          }
        },
        {
          "name": "Creative Tags Report",
          "logo": "mdi-tag",
          "isActive": true,
          "description": "Generate Creative Tag report.",
          "parameters": [],
          submit: async () => {
            await this.$rest.exports.post_resource({
              "exportType": "creative_tag",
              "exportData": {},
            });

            await this.$router.push({name: "ExportsView"});
          },
        },
        // TODO: probably bring these items into several file and merge them here - better management & cleaner
        // eg: reportA.js reportB.js ... | export const reportA = {...}
        // then here: [reportA, reportB, reportC,...]
      ];

      if (this.$store.getters['user/isReporting']) {
        commonReports.splice(2, 0, ...[
          {
            "name": "Engagement Report",
            "logo": "mdi-account-multiple",
            "isActive": true,
            "link": "/reports/engagement"
          },
          {
            "name": "Progressive Lead Score",
            "logo": "mdi-chart-line",
            "isActive": true,
            "description": "Generate a CSV report that swill show progression of a contacts leads score over a period of time.",
            "parameters": [
              {
                type: 'choice',
                key: 'startMonth',
                label: 'Start month',
                placeholder: 'Select a month',
                items: () => range(1, 13),
              },
              {
                type: 'choice',
                key: 'startYear',
                label: 'Start year',
                placeholder: 'Select a year',
                items: () => {
                  const currentYear = new Date().getFullYear()
                  return range(currentYear, currentYear - 11 , -1)
                },
              },
              {
                type: 'choice',
                key: 'endMonth',
                label: 'End Month',
                placeholder: 'Select a month',
                items: () => range(1, 13),
              },
              {
                type: 'choice',
                key: 'endYear',
                label: 'End year',
                placeholder: 'Select a year',
                items: () => {
                  const currentYear = new Date().getFullYear()
                  return range(currentYear, currentYear - 11 , -1)
                },
              },
            ],
            submit: async (values) => {
              await this.$rest.exports.post_resource({
                "exportType": "progressive_lead_score",
                "exportData": {
                  "startMonth": values.startMonth,
                  "startYear": values.startYear,
                  "endMonth": values.endMonth,
                  "endYear": values.endYear,
                },
              });

              await this.$router.push({name: "ExportsView"});
            },
          },
        ]);
        commonReports.push({
          "name": "Email Subscription Status",
          "logo": "mdi-email-alert",
          "isActive": true,
          "description": "Generate Email Subscription Status report.",
          "parameters": [
            {
              // for choice, we can have `items` as an Array of Object ([{value,label},...])
              // or a closure to load the items from server-side
              type: 'choice',
              key: 'segment',
              label: 'Segment',
              placeholder: 'Select a Segment',
              value: 0,
              items: async () => {
                const response = await this.$rest.segment.get_collection({
                  ignorePagination: true,
                  sort: ['name:asc'],
                  isArchived: false,
                });

                if (!response.data) {
                  return [];
                }

                const items = response.data.items.map((item) => ({
                  value: item.id,
                  label: item.name,
                }));

                items.unshift({
                  value: 0,
                  label: 'All Contacts (No Segment)',
                });

                return items;
              },
            }
          ],
          submit: async (values) => {
            await this.$rest.exports.post_resource({
              "exportType": "email_subscription_status",
              "exportData": {
                "segmentId": values.segment,
              },
            });

            await this.$router.push({ name: "ExportsView" });
          },
        });
      } else if (this.$store.getters['user/hasNoContactDataRole']) {
        commonReports.splice(2, 1)
      }

      return commonReports;
    }
  },
  async created() {

  },
  methods: {
    select_report(report) {
      this.selected_report = report;
      if(report.link){
        this.$router.push(report.link)
      }

      if(report.parameters){
        this.dialog = true;
      }
    },
    async generateReport(report, values) {
      if (typeof report.submit === 'function') {
        await report.submit(values);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active-accounts-checkbox {
  .v-input__control {
    .v-messages {
      display: none;
    }
  }
}
.mobile-view {
  > div {
    width: 265px;
  }
}
</style>
