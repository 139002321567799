<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      Configure Report
    </v-card-title>
    <v-divider />
    <v-card-text class="plugin-popup px-6 pt-5">
      <div class="d-flex align-center mt-1">
        <div
          class="plugin-popup__image-background d-flex align-center justify-center mr-3"
        >
          <v-img
            v-if="report.logo"
            :src="report.logo"
            max-height="35px"
            contain
          />
        </div>
        <div>
          <div class="plugin-popup__name">
            {{ report.name }}
          </div>
        </div>
        <v-spacer />
      </div>

      <div class="mt-5">
        <div class="subtitle-text mb-2">
          Description
        </div>
        <div class="regular-text-gray">
          {{ report.description }}
        </div>
      </div>

      <div
        v-if="fields && fields.length"
        class="mt-5"
      >
        <div class="subtitle-text mb-2">
          Report Parameters
        </div>
      </div>

      <div
        v-for="(field, index) in fields"
        :key="index"
        class="mt-5"
      >
        <div
          class="subtitle-text"
          v-text="field.label"
        />
        <template
          v-if="field.type === 'choice'"
        >
          <!--- Choice / Dropdown --->
          <CustomDropdown
            v-model="field.value"
            :placeholder="field.placeholder || ''"
            :items="field.items"
            :required="field.required || false"
          />
        </template>
        <template
          v-else-if="field.type === 'text'"
        >
          <!--- text / Text Input --->
          <CustomTextInput
            v-model="field.value"
            :placeholder="field.placeholder || ''"
            :required="field.required || false"
          />
        </template>
      </div>
    </v-card-text>
    <v-divider />
    <div class="d-flex justify-end align-center px-6 pb-3 pt-2">
      <div
        class="simple-text-button button--gray px-9"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>
      <v-btn
        class="custom-button custom-button--blue px-15"
        height="34px"
        depressed
        @click="submit"
      >
        Generate
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "ConfigureReportPlugin",
  components: { CustomTextInput, CustomDropdown },
  props: {
    report: {
      default: () => {},
      type: Object,
    },
  },
  data: () => ({
    fields: [],
  }),
  async created() {
    await this.resolveConfigurations();
  },
  methods: {
    /**
     * Parse configuration here
     */
    async resolveConfigurations() {
      for (const field of this.report.parameters) {
        const configField = {
          value: null, // for v-model
          ...field,
        };

        // parse by type
        if (configField.type === 'choice') {
          // if this is function => await get items => trigger method to get item options
          if (typeof configField.items === 'function') {
            configField.items = await configField.items();
          }
        }

        this.fields.push(configField);
      }
    },
    async submit() {
      // pack the data and submit
      const values = {};

      for (const field of this.fields) {
        values[field.key] = field.value;
      }

      this.$emit('click', values);
    },
  },
};
</script>

<style lang="scss" scoped>
.plugin-popup {
  &__image-background {
    height: 80px;
    width: 80px;
    background: rgba(216, 216, 216, 0.2);
  }
  &__name {
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    line-height: normal;
    color: #241c15;
  }
  &__link {
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    line-height: normal;
    color: #2b84eb;
  }
  &__installed {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    color: #2b84eb;
  }
  &__not-installed {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: normal;
    color: #66788e;
  }

  .subtitle-text {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #241c15;
  }
  .regular-text {
    font-size: 13px;
    font-stretch: normal;
    line-height: normal;
    color: #241c15;
    font-family: "Open Sans", sans-serif;
    &-gray {
      font-size: 13px;
      color: #66788e;
      font-family: "Open Sans", sans-serif;
    }
  }

  .v-input.plugin-popup-checkbox {
    margin: 0;
    padding-top: 0;
    .v-input__control {
      .v-messages {
        display: none !important;
      }
      .v-input__slot {
        margin-bottom: 3px;
        .v-label {
          font-size: 13px;
          font-stretch: normal;
          line-height: normal;
          color: #241c15;
          font-family: "Open Sans", sans-serif;
          padding-top: 1px;
        }
        .v-icon {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
