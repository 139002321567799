<template>
  <v-card
    class="mr-4 my-2 elevation-0 report-cart"
    height="160px"
    max-width="265px"
    min-width="265px"
    width="265px"
    @click="$emit('click')"
  >
    <div class="card-background d-flex align-center justify-center">
      <v-icon
        size="42"
        color="#2b84eb"
      >
        {{ report.logo }}
      </v-icon>
    </div>
    <v-card-actions>
      <div class="point-name">
        {{ report.name || 'Unnamed Report' }}
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
//import OuterMenu from "@/views/Plugins/components/OuterMenu";

export default {
  name: "ReportCard",
  components: {
   // OuterMenu
  },
  props: {
    report: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      outer_menu_opened: false,
    }
  },
};
</script>

<style lang="scss" scoped>
.report-cart {
  width: 262px;
  height: 159px;
  margin: 0 1px 0 0;
  padding: 0 0 17px;
  border-radius: 5px;
  border: solid 1px #dee7ee;
  background-color: #ffffff;

  .card-background {
    background: #f7f7f7;
    height: 108px;
    border-bottom: 1px solid #dee7ee;
  }
}

.point-name {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}
</style>
